import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { makeURL } from '../config'
import { ApiError } from '../errors/ApiError'
import { ServerError } from '../errors/ServerError'
import { RainGaugeDTO } from './dto/RainGaugeDTO'
import { RainGaugeTimeSeriesDTO } from './dto/RainGaugeTimeSeriesDTO'

export class RainGaugeAPI {
    private routes = {
        getRainGaugesOfCity: makeURL('/api/stations/${city}?timestamp=${timestamp}'),
        getForecasts: makeURL('/api/raingauges/${rain_gauge_id}/timeseries/${timestamp}')
    }

    async getRainGaugesForCity(city: string, timestamp: DateTime): Promise<RainGaugeDTO[]> {
        try {
            let url = this.routes.getRainGaugesOfCity
                .replace('${city}', city)

            if (timestamp) {
                url = url.replace('${timestamp}', timestamp.toISO());
            } else {
                url = url.replace('${timestamp}', '');
            }

            const response = await axios.get(url)
            return response.data as RainGaugeDTO[]
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getTimeseriesForRainGaugeByTimestamp(
        rainGaugeId: number,
        utcTimestamp: DateTime
    ): Promise<RainGaugeTimeSeriesDTO> {
        try {
            const url = this.routes.getForecasts
                .replace('${rain_gauge_id}', rainGaugeId.toString())
                .replace('${timestamp}', utcTimestamp.toISO())
            const response = await axios.get(url)
            return response.data as RainGaugeTimeSeriesDTO
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}
