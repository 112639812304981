import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { makeURL } from '../config'
import { ApiError } from '../errors/ApiError'
import { ServerError } from '../errors/ServerError'
import { StationDTO } from './dto/StationDTO'
import { TimeSeriesDTO } from './dto/TimeSeriesDTO'

export class StationAPI {
    private routes = {
        getStationsOfCity: makeURL('/api/stations/${city_name}?timestamp=${timestamp}'),
        getForecasts: makeURL('/api/stations/${station_id}/timeseries/${timestamp}')
    }

    async getStationsOfCity(cityName: string): Promise<StationDTO[]>;
    async getStationsOfCity(cityName: string, timestamp: DateTime): Promise<StationDTO[]>;

    async getStationsOfCity(cityName: string, timestamp?: DateTime): Promise<StationDTO[]> {
        try {
            let url = this.routes.getStationsOfCity.replace('${city_name}', cityName.toString());
            if (timestamp) {
                url = url.replace('${timestamp}', timestamp.toISO());
            } else {
                url = url.replace('${timestamp}', '');
            }

            const rawStations = await axios.get(url);
            const res = rawStations.data as StationDTO[];
            return res;
        } catch (e) {
            const error = e as AxiosError<ServerError>;
            throw ApiError.from(error.response?.data);
        }
    }

    async getTimeseriesForStationByTimestamp(
        stationId: number,
        utcTimestamp: DateTime
    ): Promise<Map<string, TimeSeriesDTO>> {
        try {
            const url = this.routes.getForecasts
                .replace('${station_id}', stationId.toString())
                .replace('${timestamp}', utcTimestamp.toISO())
            const response = await axios.get(url)
            const result = new Map<string, TimeSeriesDTO>()

            for (const [id, raw] of Object.entries(response.data.timeseries)) {
                result.set(id, raw as TimeSeriesDTO)
            }
            return result
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}
