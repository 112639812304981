import { UserCreateDTO } from '@/api/user/dto/UserCreateDTO'
import { UserUpdateDTO } from '@/api/user/dto/UserUpdateDTO'
import { User } from '@/model/User'

/**
 * Maps User object to UserDTO.
 */
export class UserDTOFactory {
    static dtoForCreate(user: User): UserCreateDTO {
        return new UserCreateDTO(
            user.username,
            user.password,
            user.permissions.toLowerCase(),
            user.floodAccess ? 'true' : 'false',
            user.isAdmin ? 'true' : 'false',
            user.cities.map((c) => c.getName()),
            user.email
        )
    }

    static dtoForUpdate(user: User): UserUpdateDTO {
        return new UserUpdateDTO(
            user.email,
            user.permissions.toLowerCase(),
            user.floodAccess,
            user.isAdmin,
            user.cities.map((c) => c.getName()),
            user.language
        )
    }
}
