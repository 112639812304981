



























import { Component, Prop, Vue } from 'vue-property-decorator'

import { IAccessControlService } from '@/services/acl/IAccessControlService'
import { CityCollection } from '@/model/CityCollection'

import MainModeSwitch from '@/components/commands/MainModeSwitch.vue'

import CitiesSelector from './CitiesSelector.vue'
import SettingsDropdown from './SettingsDropdown.vue'

@Component({
    components: {
        CitiesSelector,
        SettingsDropdown,
        MainModeSwitch
    }
})
export default class Menu extends Vue {
    @Prop({ type: String, default: 'is-bottom' })
    tooltipPosition!: 'is-top' | 'is-bottom'

    @Prop({ type: CityCollection, default: () => new CityCollection([]) })
    cityCollection!: CityCollection

    private accessControlService!: IAccessControlService

    created(): void {
        this.accessControlService = this.$services.get<IAccessControlService>('acl')
    }

    get isMinimalUser(): boolean {
        return this.accessControlService.isMinimalUser
    }

    get selectedCityOrFirst(): string {
        const cityId = this.$route.params['cityId']
        if (cityId) {
            return cityId
        } else {
            return this.cityCollection.get(0).getName()
        }
    }
}
