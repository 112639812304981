import { Permissions } from '@/model/Permissions'
import { User } from '@/model/User'
import { IAuthService } from '../auth/IAuthService'
import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IUserService } from '../user/IUserService'
import { IAccessControlService } from './IAccessControlService'

export class AccessControlService extends BaseService implements IAccessControlService {
    private currentUser: User = User.UNKNOWN

    constructor(locator: ServiceLocator) {
        super(locator)

        const userService = locator.get<IUserService>('user')
        const authService = locator.get<IAuthService>('auth')
        if (authService.isAuthenticated()) {
            userService.getCurrentUser().then((u) => (this.currentUser = u))
        }
        authService.loggedIn.subscribe(async (arg) => {
            this.currentUser = await userService.getCurrentUser()
        })
        authService.loggedOut.subscribe(async (arg) => {
            this.currentUser = User.UNKNOWN
        })
    }

    hasPermission(permission: Permissions): boolean {
        return this.currentUser.permissions == permission
    }

    get isAdmin(): boolean {
        return this.currentUser.isAdmin
    }

    get isMinimalUser(): boolean {
        return !this.currentUser.isAdmin && !this.currentUser.floodAccess
    }

    get hasFloodAccess(): boolean {
        return this.currentUser.floodAccess
    }
}
