














import { RainGauge } from '@/model/RainGauge'
import { RainGaugeTimeSeries } from '@/model/RainGaugeTimeSeries'
import { IRainGaugeService } from '@/services/raingauge/IRainGaugeService'
import { DateTime, Interval } from 'luxon'
import { Layout, newPlot, PlotData } from 'plotly.js'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ITranslationService } from "@/services/translation/ITranslationService"

const CLOSE_ICON = {
    color: '#ffffff',
    path: 'M256,33C132.3,33,32,133.3,32,257c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C480,133.3,379.7,33,256,33z    M364.3,332.5c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.2-2.3,5.6l-21.6,21.7c-1.6,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3L256,289.8   l-75.4,75.7c-1.5,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.2,2.3-5.6l75.7-76   l-75.9-75c-3.1-3.1-3.1-8.2,0-11.3l21.6-21.7c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l75.7,74.7l75.7-74.7   c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l21.6,21.7c3.1,3.1,3.1,8.2,0,11.3l-75.9,75L364.3,332.5z',
    transform: 'matrix(1 0 0 1 -2 -2) scale(0.05)'
}

@Component
export default class RainGaugeChart extends Vue {
    @Prop() rainGauge!: RainGauge
    @Prop() timestamp!: DateTime
    @Prop() timeperiod!: Interval

    @Ref('chart') chartElement!: HTMLDivElement

    private loaded = false
    private hasData = false
    private rainGaugeService!: IRainGaugeService
    private translationService!: ITranslationService

    created(): void {
        this.rainGaugeService = this.$services.get<IRainGaugeService>('raingauges')
        this.translationService = this.$services.get<ITranslationService>('translation')
    }

    async mounted(): Promise<void> {
        let rainGaugeData = undefined

        if (this.timestamp != null) {
            const timeSeries = await this.rainGaugeService.getTimeSeriesForRainGauge(this.rainGauge, this.timestamp)
            rainGaugeData = timeSeries
        }

        this.loaded = true

        if (rainGaugeData) {
            this.hasData = true

            newPlot(this.chartElement, this.mapToPlotData(rainGaugeData), this.getLayoutFor(this.rainGauge, rainGaugeData), {
                modeBarButtonsToRemove: [
                    'toImage',
                    'autoScale2d',
                    'zoom2d',
                    'pan2d',
                    'select2d',
                    'lasso2d',
                    'zoomIn2d',
                    'zoomOut2d',
                    'resetScale2d'
                ],
                modeBarButtonsToAdd: [
                    {
                        title: 'Close',
                        name: 'closeButton',
                        icon: CLOSE_ICON,
                        click: this.onCloseRequested
                    }
                ]
            })
        }
    }

    mapToPlotData(timeseries: RainGaugeTimeSeries): PlotData[] {
        const plotData: PlotData[] = []

        if (timeseries.hasRainfallIntensity) {
            // TODO fix this mismatch in units somewhere else :-)
            const rainfallIntensityMm = timeseries.rainfallIntensity.getValues()
            const rainfallIntensityMmHour = rainfallIntensityMm.map(i => i) // was * 12

            plotData.push({
                x: [...timeseries.rainfallIntensity].map((ts) => ts.setZone(this.getZoneName()).toString()),
                y: rainfallIntensityMmHour,
                hovertemplate: `%{y} ${this.translationService.translate("model.representation.rainfall.unit")}`,
                type: 'bar',
                name: this.translationService.translate("model.representation.rainfall"),
                line: {
                    color: '#1F77B4'
                }
            } as PlotData)
        }
        if (timeseries.hasRainfallAccumulated) {
            plotData.push({
                x: [...timeseries.rainfallAccumulated].map((ts) => ts.setZone(this.getZoneName()).toString()),
                y: timeseries.rainfallAccumulated.getValues(),
                hovertemplate: `%{y} ${this.translationService.translate("model.representation.rainfall_accumulated.unit")}`,
                type: 'scatter',
                name: this.translationService.translate("model.representation.rainfall_accumulated"),
                yaxis: "y2",
                line: {
                    color: '#000000'
                }
            } as PlotData)
        }

        return plotData
    }

    private getZoneName(): string {
        if (this.timestamp) {
            return this.timestamp.zoneName
        } else if (this.timeperiod) {
            return this.timeperiod.start.zoneName
        } else {
            return 'utc'
        }
    }

    private onCloseRequested(): void {
        this.rainGaugeService.toggleRainGauge(this.rainGauge)
    }

    private getLayoutFor(rainGauge: RainGauge, timeSeries: RainGaugeTimeSeries): Layout {
        const startDate = timeSeries.getStart(this.getZoneName()).toString()
        const endDate = timeSeries.getEnd(this.getZoneName()).toString()

        const intensityValues = timeSeries.rainfallIntensity.getValues()
        const maxValueIntensity = Math.max(...intensityValues)

        const accumulatedValues = timeSeries.rainfallAccumulated.getValues()
        const maxValueAccumulated = Math.max(...accumulatedValues)

        const intensityTitle = `${this.translationService.translate("model.representation.rainfall")} (${this.translationService.translate("model.representation.rainfall.unit")})`
        const accumulatedTitle = `${this.translationService.translate("model.representation.rainfall_accumulated")} (${this.translationService.translate("model.representation.rainfall_accumulated.unit")})`

        let layout = {
            title: rainGauge.name,
            width: 544,
            height: 300,
            yaxis: {
                showticklabels: true,
                // range: [0, maxValueIntensity + 5],
                title: {
                    text: intensityTitle,
                    standoff: 10
                }
            },
            xaxis: {
                range: [startDate, endDate],
                visible: true,
            },
            legend: {
                x: 0.0,
                y: -1,
                xanchor: 'center',
                orientation: 'h'
            },
            showlegend: false,
            hovermode: 'x'
        } as Layout

        if (timeSeries.hasRainfallAccumulated) {
            layout.yaxis2 = {
                showticklabels: true,
                range: [0, maxValueAccumulated + 5],
                overlaying: "y",
                side: "right",
                title: {
                    text: accumulatedTitle,
                    standoff: 10
                }
            }
            layout.shapes = [{
                type: 'line',
                x0: startDate,
                x1: endDate,
                y0: 100,
                y1: 100,
                line: {
                    color: '#FFAC1C',
                    width: 2
                },
                yref: 'y2'
            }, {
                type: 'line',
                x0: startDate,
                x1: endDate,
                y0: 150,
                y1: 150,
                line: {
                    color: '#DB1010',
                    width: 2
                },
                yref: 'y2'
            }]
        }
        return layout
    }
}
