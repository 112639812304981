import { FeatureCollection, Point, Polygon } from 'geojson'
import mapboxgl, { AnyLayer, GeoJSONSource, MapEventType, MapLayerEventType, Marker, Popup } from 'mapbox-gl'
import bbox from '@turf/bbox'
import { BBox } from '@turf/turf'
import 'mapbox-gl/dist/mapbox-gl.css'
// Radius of the earth in kilometer
const EARTH_RADIUS = 6378.137

// 1 meter in degree
const METER_TO_DEGREE = 1 / (((2 * Math.PI) / 360) * EARTH_RADIUS) / 1000

export class MapBoxGL {
    public readonly applicationPrefix: string
    public readonly emptyGeoJson: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    }

    protected readonly htmlContainer: HTMLElement
    protected readonly onLoaded: () => void
    protected readonly map: mapboxgl.Map
    protected popup: Popup
    protected chartPopup: Popup
    protected ready = false

    constructor(
        applicationPrefix: string,
        htmlContainer: HTMLElement,
        accessToken: string,
        center: [number, number],
        bounds: number[],
        styleUrl: string,
        onLoaded: () => void
    ) {
        this.applicationPrefix = applicationPrefix
        mapboxgl.accessToken = accessToken
        this.htmlContainer = htmlContainer
        this.onLoaded = onLoaded

        let maxBounds
        if (!bounds || bounds.length == 0) {
            maxBounds = undefined
        } else {
            maxBounds = bounds as [number, number, number, number]
        }

        this.map = new mapboxgl.Map({
            container: this.htmlContainer,
            style: styleUrl,
            attributionControl: false,
            dragRotate: false,
            pitchWithRotate: false,
            logoPosition: 'bottom-left',
            center: center,
            maxBounds: maxBounds,
            zoom: 8
        })
        this.popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
        this.chartPopup = new mapboxgl.Popup({ closeButton: true, closeOnClick: false })

        this.map.on('load', () => {
            this.ready = true
            this.onLoaded()
        })
    }

    isReady(): boolean {
        return this.ready
    }

    getZoom(): number {
        return this.map.getZoom()
    }

    addSymbol(name: string, symbol: { url: string; sdf: boolean }): void {
        this.map.loadImage(symbol.url, (error, image) => {
            if (error) {
                throw error
            }
            if (!image) {
                throw TypeError(`Image couldn't be loaded at URL ${symbol.url}`)
            }
            this.map.addImage(name, image, { sdf: symbol.sdf })
        })
    }

    addCursor(layer: string, cursor: string): void {
        const defaultCursor = this.map.getCanvas().style.cursor

        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        this.map.on('mousemove', layer, (e) => {
            this.map.getCanvas().style.cursor = cursor
        })

        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        this.map.on('mouseleave', layer, (e) => {
            this.map.getCanvas().style.cursor = defaultCursor
        })
    }

    addLayer(layer: AnyLayer): void {
        this.map.addLayer(layer)
    }

    addEventHandler(name: keyof MapEventType, callback: (ev: mapboxgl.MapboxEvent) => void): void {
        this.map.on(name, callback)
    }

    addLayerEventHandler(
        name: keyof MapLayerEventType,
        layerName: string,
        callback: (ev: (mapboxgl.MapLayerMouseEvent | mapboxgl.MapLayerTouchEvent) & mapboxgl.EventData) => void
    ): void {
        this.map.on(name, layerName, callback)
    }

    addSource(name: string): void {
        this.map.addSource(name, {
            type: 'geojson',
            data: this.emptyGeoJson
        })
    }

    updateSource(name: string, featureCollection: FeatureCollection): void {
        const source = this.map.getSource(name) as GeoJSONSource
        if (source === undefined) {
            throw Error(`MapBoxGL: Source ${name} doesn't exist.`)
        }
        source.setData(featureCollection)
    }

    // TODO: change state type (any) to custom FeatureState class
    // eslint-disable-next-line
    updateFeatureState(sourceName: string, featureId: string, state: any): void {
        this.map.setFeatureState({ source: sourceName, id: featureId }, state)
    }

    showOnlyLayers(names: string[]): void {
        const layers = this.map.getStyle().layers?.filter((layer) => layer.id.startsWith(this.applicationPrefix))
        if (!layers) {
            return
        }

        for (const layer of layers) {
            let keepThisLayer = false

            for (const name of names) {
                if (
                    name === layer.id ||
                    (name.endsWith('*') && layer.id.startsWith(name.substring(0, name.length - 2)))
                ) {
                    keepThisLayer = true
                    break
                }
            }

            if (keepThisLayer) {
                if (this.map.getLayoutProperty(layer.id, 'visibility') !== 'visible') {
                    this.map.setLayoutProperty(layer.id, 'visibility', 'visible')
                }
            } else {
                if (this.map.getLayoutProperty(layer.id, 'visibility') !== 'none') {
                    this.map.setLayoutProperty(layer.id, 'visibility', 'none')
                }
            }
        }
    }

    showPopup(position: mapboxgl.LngLat, text: string): void {
        this.popup.setLngLat(position)
        this.popup.setHTML(text)
        this.popup.addTo(this.map)
    }

    hidePopup(): void {
        this.popup.remove()
    }

    setOpacityForLayer(layerId: string, opacity: number): void {
        this.map.setPaintProperty(layerId, 'fill-opacity', opacity / 100)
    }

    jumpTo(location: [number, number]): void {
        this.map.jumpTo({ center: location })
    }

    zoom(featureCollection: FeatureCollection, paddingInPixels = 100): void {
        const box = bbox(featureCollection)
        if (!Number.isFinite(box[0])) {
            return
        }

        this.map.fitBounds(
            [
                [box[0], box[1]],
                [box[2], box[3]]
            ],
            {
                padding: paddingInPixels
            }
        )
    }

    static convertPointToPolygon(point: Point, scale = 500): Polygon {
        const coords = point.coordinates
        const halfScale = scale / 2

        const bbox: BBox = [
            this.offsetLongitude(coords[0], coords[1], -halfScale),
            this.offsetLatitude(coords[1], -halfScale),
            this.offsetLongitude(coords[0], coords[1], halfScale),
            this.offsetLatitude(coords[1], halfScale)
        ]

        return {
            type: 'Polygon',
            coordinates: [
                [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[1]],
                    [bbox[2], bbox[3]],
                    [bbox[0], bbox[3]],
                    [bbox[0], bbox[1]]
                ]
            ]
        }
    }

    static offsetLatitude(latitude: number, offsetInMeters: number): number {
        return latitude + offsetInMeters * METER_TO_DEGREE
    }

    static offsetLongitude(longitude: number, latitude: number, offsetInMeters: number): number {
        return longitude + (offsetInMeters * METER_TO_DEGREE) / Math.cos(latitude * (Math.PI / 180))
    }
}
