import { EventEmitter } from '@/model/EventEmitter'
import { RainGauge } from '@/model/RainGauge'
import { RainGaugeTimeSeries } from '@/model/RainGaugeTimeSeries'
import { FeatureCollection } from 'geojson'
import { DateTime } from 'luxon'

type SelectionAction = 'add' | 'remove'

export class RainGaugeSelectionEventArgs {
    constructor(readonly action: SelectionAction, readonly rainGauges: RainGauge[]) {}
}

export interface RainGaugeLegend {
    level: number
    label: string
    color: string
}

export interface IRainGaugeService {
    readonly rainGaugeSelectionChanged: EventEmitter<RainGaugeSelectionEventArgs>

    getTimeSeriesForRainGauge(rainGauge: RainGauge, timestamp: DateTime): Promise<RainGaugeTimeSeries>

    getRainGaugesOfCity(city: string, timestamp: DateTime): Promise<Array<RainGauge>>
    mapToFeatureCollection(rainGauges: RainGauge[]): Promise<FeatureCollection>

    toggleRainGauge(rainGauge: RainGauge): Promise<void>
    clearRainGauges(): void
}
