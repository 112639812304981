export class UserUpdateDTO {
    constructor(
        readonly email: string,
        readonly rights: string,
        readonly flood: boolean,
        readonly admin: boolean,
        readonly cities: string[],
        readonly language: string
    ) {}
}
