import { MapBoxGL } from '../MapBoxGL'

import { IRainGaugeService, RainGaugeSelectionEventArgs } from '@/services/raingauge/IRainGaugeService'
import { ServiceLocator } from '@/services/ServiceLocator'

import { AnyLayer, EventData, MapLayerMouseEvent, MapLayerTouchEvent, SymbolLayer } from 'mapbox-gl'

import { RainGauge } from '@/model/RainGauge'
import { City } from '@/model/City'
import { BaseController } from './BaseController'
import { IController } from './IController'
import { IControllerParent } from './IControllerParent'
import { IMapService } from '@/services/map/IMapService'
import { IStationService }  from "@/services/station/IStationService"
import { TimeStep } from '@/model/TimeStep'

const DEFAULT_SOURCE = 'all-raingauges'
const UNSELECTED_SOURCE = 'unselected-raingauges'
const SELECTED_SOURCE = 'selected-raingauges'

/**
 * Handle user interactions related to raingauge markers
 */
export class RainGaugeController extends BaseController implements IController {
    private rainGaugeService: IRainGaugeService
    private stationService: IStationService
    private unselectedRainGauges: Map<number, RainGauge> = new Map()
    private selectedRainGauges: Map<number, RainGauge> = new Map()

    private city: City | null = null
    private count = 0
    private handler = async (evt: RainGaugeSelectionEventArgs) => await this.updateFeatureState(evt)

    constructor(
        parent: IControllerParent,
        mapBoxGL: MapBoxGL,
        mapService: IMapService,
        serviceLocator: ServiceLocator
    ) {
        super(parent, mapBoxGL, mapService)
        this.stationService = serviceLocator.get<IStationService>("stations")
        this.rainGaugeService = serviceLocator.get<IRainGaugeService>('raingauges')
        this.rainGaugeService.rainGaugeSelectionChanged.subscribe(this.handler)
    }

    beforeDestroy(): void {
        this.rainGaugeService.rainGaugeSelectionChanged.unsubscribe(this.handler)
    }

    async load(city: City, timeStep?: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        if (!timeStep) {
            return false
        }
        if (this.city == null || this.city.getName() !== timeStep.snapshot.city.getName()) {
            this.city = timeStep.snapshot.city
            await this.reloadRainGauges(timeStep)
        }
        return true
    }

    async reloadRainGauges(timeStep: TimeStep): Promise<void> {
        if (this.city == null) return
        this.unselectedRainGauges.clear()
        this.selectedRainGauges.clear()

        const timestamp = timeStep.timestamp.toUTC();

        const stations = await this.rainGaugeService.getRainGaugesOfCity(this.city.getName(), timestamp)
        const rainGauges = stations.filter(s => s.stationTypes.includes("Rainfall"))
        rainGauges.forEach((s) => this.unselectedRainGauges.set(s.id, s))

        const unselectedFeatures = await this.rainGaugeService.mapToFeatureCollection([...this.unselectedRainGauges.values()])
        this.mapBoxGL.updateSource(UNSELECTED_SOURCE, unselectedFeatures)

        const selectedFeatures = await this.rainGaugeService.mapToFeatureCollection([...this.selectedRainGauges.values()])
        this.mapBoxGL.updateSource(SELECTED_SOURCE, selectedFeatures)

        // TODO Ward improve this mechanism to avoid adding the click layer event handler twice
        if (this.count == 0) {
            this.mapBoxGL.addLayerEventHandler(
                'click',
                `${this.mapBoxGL.applicationPrefix}-unselectedraingauges`,
                async (evt) => this.onMarkerClicked(evt)
            )

            this.mapBoxGL.addLayerEventHandler(
                'click',
                `${this.mapBoxGL.applicationPrefix}-selectedraingauges`,
                async (evt) => this.onMarkerClicked(evt)
            )
        }
        this.count++
    }

    async onMarkerClicked(e: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData): Promise<void> {
        if (e.features && e.features[0] && e.features[0].properties) {
            const featureProperties = e.features[0].properties
            const id = featureProperties['id']
            const rainGaugeSource = this.unselectedRainGauges.has(id) ? this.unselectedRainGauges : this.selectedRainGauges
            await this.rainGaugeService.toggleRainGauge(rainGaugeSource.get(id) as RainGauge)
        }
    }

    async updateFeatureState(args: RainGaugeSelectionEventArgs): Promise<void> {
        const isAddAction = args.action === 'add'

        if (isAddAction) {
            args.rainGauges.forEach((r) => this.selectedRainGauges.set(r.id, r))
            args.rainGauges.forEach((r) => this.unselectedRainGauges.delete(r.id))
        } else {
            args.rainGauges.forEach((r) => this.selectedRainGauges.delete(r.id))
            args.rainGauges.forEach((r) => this.unselectedRainGauges.set(r.id, r))
        }

        const unselectedFeatures = await this.rainGaugeService.mapToFeatureCollection([...this.unselectedRainGauges.values()])
        this.mapBoxGL.updateSource(
            UNSELECTED_SOURCE, unselectedFeatures
        )
        const selectedFeatures = await this.rainGaugeService.mapToFeatureCollection([...this.selectedRainGauges.values()])
        this.mapBoxGL.updateSource(
            SELECTED_SOURCE, selectedFeatures
        )
        const allFeatures = await this.rainGaugeService.mapToFeatureCollection([
            ...this.selectedRainGauges.values(),
            ...this.unselectedRainGauges.values()
        ])
        this.mapBoxGL.updateSource(
            DEFAULT_SOURCE, allFeatures
        )
    }

    clearRainGauges(): void {
        this.rainGaugeService.clearRainGauges()
    }

    getLayers(): AnyLayer[] {
        return [
            {
                id: `${this.mapBoxGL.applicationPrefix}-unselectedraingauges`,
                type: 'symbol',
                source: UNSELECTED_SOURCE,
                minzoom: 3,
                layout: {
                    'icon-image': 'unselected_marker_rain_gauge',
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-ignore-placement': true,
                    'text-size': 0
                },
                paint: {
                    'icon-color': ["get", "color"]
                }
            } as SymbolLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-selectedraingauges`,
                type: 'symbol',
                source: SELECTED_SOURCE,
                minzoom: 3,
                layout: {
                    'icon-image': 'selected_marker_rain_gauge',
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-ignore-placement': true,
                    'text-size': 0
                },
                paint: {
                    'icon-color': ["get", "color"],
                }
            } as SymbolLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-raingaugestext`,
                type: 'symbol',
                source: DEFAULT_SOURCE,
                minzoom: 3,
                layout: {
                    'text-field': ['get', 'name_no'],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-offset': [0, 1.5],
                    'text-anchor': 'top',
                    'text-size': 10,
                    'icon-size': 0.01,
                    'icon-allow-overlap': false,
                    'text-allow-overlap': false,
                    'icon-ignore-placement': false,
                    'text-ignore-placement': false
                },
                paint: {
                    'text-halo-color': '#ffffff',
                    'text-halo-blur': 1,
                    'text-halo-width': 2
                }
            } as SymbolLayer
        ]
    }

    getSources(): string[] {
        return [UNSELECTED_SOURCE, SELECTED_SOURCE, DEFAULT_SOURCE]
    }

    getCursors(): Map<string, string> {
        return new Map([
            [`${this.mapBoxGL.applicationPrefix}-selectedraingauges`, 'pointer'],
            [`${this.mapBoxGL.applicationPrefix}-unselectedraingauges`, 'pointer'],
            [`${this.mapBoxGL.applicationPrefix}-raingaugesstext`, 'pointer']
        ])
    }

    getSymbols(): Map<string, { url: string; sdf: boolean }> {
        return new Map([
            [
                'unselected_marker_rain_gauge',
                {
                    url: require('@/assets/marker.png'),
                    sdf: true
                }
            ],
            [
                'selected_marker_rain_gauge',
                {
                    url: require('@/assets/marker.png'),
                    sdf: true
                }
            ]
        ])
    }
}
